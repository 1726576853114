<template>
  <b-sidebar
    id="signup-course"
    :visible="isSignupActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-signup-active', val)"
  >
    <template #default="{ hide }">
      <div
        v-if="tableItem"
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ tableItem.full_course_name }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <validation-observer
        v-if="tableItem"
        #default="{ handleSubmit }"
        ref="signUpForm"
      >
        <!-- Form -->
        <b-form
          class="m-1"
          @submit.prevent="handleSubmit(signUp(tableItem.id))"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col>
              <validation-provider
                v-if="!userData.firstname"
                #default="validationContext"
                name="First name"
                rules="required"
              >

                <b-form-group
                  label="First name"
                  label-for="last-name"
                >
                  <b-form-input
                    v-model="register.firstname"
                    :state="getValidationState(validationContext)"
                    type="text"
                    placeholder="First name"
                    class="form-control"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-if="!userData.lastname"
                #default="validationContext"
                name="Last name"
                rules="required"
              >

                <b-form-group
                  label="Last name"
                  label-for="last-name"
                >
                  <b-form-input
                    v-model="register.lastname"
                    :state="getValidationState(validationContext)"
                    type="text"
                    placeholder="Last name"
                    class="form-control"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-if="!userData.email"
                #default="validationContext"
                name="Last name"
                rules="required|email"
              >

                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    v-model="register.email"
                    :state="getValidationState(validationContext)"
                    type="email"
                    placeholder="Email"
                    class="form-control"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-if="!userData.dob"
                #default="validationContext"
                name="Birth date"
                rules="required"
              >

                <b-form-group
                  label="Birth date"
                  label-for="birth-date"
                  :state="getValidationState(validationContext)"
                >
                  <flat-pickr
                    v-model="register.dob"
                    static
                    class="form-control"
                    :config="{ dateFormat: 'Y-m-d', altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 }, maxDate: lastYearForAge18, minDate: lastYearForAge100 }"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-if="!userData.phone"
                #default="validationContext"
                name="Phone"
                rules="required"
              >

                <b-form-group
                  :label="$t('phone')"
                  label-for="phone"
                >
                  <b-form-input
                    id="mobile"
                    v-model="register.phone"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <!-- Payer -->
            <b-col
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="Payer"
                rules="required"
              >
                <!-- <label for="who-pays">Who will be paying?</label> -->
                <b-form-group
                  label="Payer"
                  label-for="who-pays"
                  :state="getValidationState(validationContext)"
                  class="flex-fill"
                >
                  <v-select
                    v-model="who_pays"
                    :options="payerOptions"
                    label="name"
                    placeholder="Select the payer"
                    :clearable="false"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-alert
                v-if="who_pays && who_pays.code === 'duo'"
                class="p-1"
                show
                style="background: #ffe9d9 !important"
              >
                <div class="alert-body">
                  <feather-icon
                    icon="AlertCircleIcon"
                    class="mr-25 text-danger"
                  />
                  <span class="text-secondary">
                    Please contact the school to pay with DUO, or choose a different payer
                  </span>
                </div>
              </b-alert>
            </b-col>
            <!-- Payer name -->
            <b-col
              v-if="who_pays && (who_pays.code === 'payer' || who_pays.code === 'company')"
              cols="12"
              class="mb-2"
            >
              <validation-provider
                #default="validationContext"
                name="Payer name"
                rules="required"
              >
                <label for="payer-name">Payer name</label>
                <b-form-input
                  v-model="register.payer_name"
                  :state="getValidationState(validationContext)"
                  type="text"
                  :placeholder="who_pays.code === 'payer' ? 'Payer name' : 'Company name'"
                  class="form-control"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <!-- Company number -->
            <b-col
              v-if="who_pays === 'company'"
              cols="12"
              class="mb-2"
            >
              <validation-provider
                #default="validationContext"
                name="Company number"
                rules="required"
              >
                <label for="company-number">Company org.number</label>
                <b-form-input
                  v-model="register.company_number"
                  :state="getValidationState(validationContext)"
                  type="text"
                  placeholder="Company org.number"
                  class="form-control"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <!-- Invoicing address -->
            <b-col
              v-if="who_pays && (who_pays.code === 'payer' || who_pays.code === 'company')"
              cols="12"
              class="mb-2"
            >
              <validation-provider
                #default="validationContext"
                name="Invoicing address"
                rules="required"
              >
                <label for="invoicing-address">Invoicing address</label>
                <b-form-input
                  v-model="register.payer_address"
                  :state="getValidationState(validationContext)"
                  type="text"
                  placeholder="Invoicing address"
                  class="form-control"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <!-- Responsible -->
            <b-col
              v-if="who_pays && who_pays.code === 'company'"
              cols="12"
              class="mb-2"
            >
              <validation-provider
                #default="validationContext"
                name="Responsible"
                rules="required"
              >
                <label for="responsible">Responsible / contact person</label>
                <b-form-input
                  v-model="register.responsible"
                  :state="getValidationState(validationContext)"
                  type="text"
                  placeholder="Responsible / contact payer"
                  class="form-control"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <!-- Payer phone -->
            <b-col
              v-if="who_pays && who_pays && (who_pays.code === 'payer' || who_pays.code === 'company')"
              cols="12"
              class="mb-2"
            >
              <validation-provider
                #default="validationContext"
                name="Phone"
                rules="required"
              >
                <label for="phone">Phone number</label>
                <b-form-input
                  v-model="register.payer_phone"
                  :state="getValidationState(validationContext)"
                  type="text"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43"
                  placeholder="Phone number"
                  class="form-control"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <!-- Payer email -->
            <b-col
              v-if="who_pays && who_pays && (who_pays.code === 'payer' || who_pays.code === 'company')"
              cols="12"
              class="mb-2"
            >
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="required|email"
              >
                <b-form-group
                  :label="('Email')"
                  label-for="email"
                >
                  <b-form-input
                    v-model="register.payer_email"
                    :state="getValidationState(validationContext)"
                    type="email"
                    autofocus
                    placeholder="Email address"
                    class="form-control"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Company payment method -->
            <b-col
              v-if="who_pays && who_pays.code === 'company'"
              cols="12"
              class="mb-2"
            >
              <div class="d-inline-block">
                <label for="email">Payment preference</label>
                <b-form-radio
                  v-model="companyPaymentMethod"
                  class="mt-50"
                  value="Card"
                >
                  By card
                </b-form-radio>
              </div>
              <div class="d-inline-block">
                <b-form-radio
                  v-model="companyPaymentMethod"
                  class="mt-50"
                  value="Invoice"
                >
                  By invoice
                </b-form-radio>
              </div>
            </b-col>
          </b-row>
          <!-- Form Actions -->
          <div class="d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="ml-1"
              type="submit"
              :disabled="!who_pays || (who_pays && who_pays.code === 'duo')"
            >
              <span v-if="tableItem.price">Sign up for {{ tableItem.price }} {{ $store.state.appConfig.currency }}</span>
              <span v-else>Sign up</span>
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
import {
  BForm, BRow, BCol, BFormInvalidFeedback, BFormGroup, BFormInput, BSidebar, BButton, BFormRadio, BAlert,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormRadio,
    BSidebar,
    BAlert,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSignupActive',
    event: 'update:is-signup-active',
  },
  props: {
    tableItem: {
      type: Object,
      required: false,
    },
    isSignupActive: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
       required: true,
    },
  },
  data() {
    return {
      lastYearForAge18: null,
      lastYearForAge100: null,
      required,
      email,
    }
  },
  mounted() {
    this.register.firstname = this.userData.firstname
    this.register.lastname = this.userData.lastname
    this.register.email = this.userData.email
    this.register.dob = this.userData.dob
    this.register.phone = this.userData.phone
    const currentDate = new Date()
    this.lastYearForAge18 = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate())
    this.lastYearForAge100 = new Date(currentDate.getFullYear() - 100, currentDate.getMonth(), currentDate.getDate())
  },
  setup(props, { emit }) {
    /* eslint-disable camelcase, no-return-assign */
    const toast = useToast()

    const payerOptions = ref([])
    const fetchPayers = () => {
      store.dispatch('registerStoreModule/fetchPayers').then(res => {
        payerOptions.value = res.data.rows
      })
    }
    fetchPayers()
    const who_pays = ref(null)
    const companyPaymentMethod = ref('Card')
    const register = ref({})
    const signUp = function (id) {
      this.$refs.signUpForm.validate().then(success => {
        if (success) {
          const user_data = { ...register.value }
          user_data.firstname = register.value.firstname
          user_data.lastname = register.value.lastname
          user_data.dob = register.value.dob
          user_data.phone = register.value.phone
          user_data.email = register.value.email
          user_data.payer_id = who_pays.value.id
          const courses = [id]
          store.dispatch('registerStoreModule/studentRegister', { user_data, companyPaymentMethod: companyPaymentMethod.value, courses })
            .then(resp => {
              /* eslint-disable no-underscore-dangle */
              if (resp.data.paymentGateway === 'mollie' && resp.data.payment.paymentId !== 'free' && resp.data.payment.paymentId !== 'invoice') {
                return window.location.href = resp.data.payment._links.checkout.href
              }
                if (resp.data.payment.paymentId !== 'free') return window.location = `${process.env.VUE_APP_CHECKOUT}?paymentId=${resp.data.payment.paymentId}&whoPayed=${who_pays.value.code}`
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Succeffuly signed up.',
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
              return emit('update:is-signup-active', false)
          }).catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    }
    const {
      resetForm,
      refFormAdd,
      refFormObserver,
      getValidationState,
    } = formValidation(() => { register.value = {}; who_pays.value = null })
    return {
      signUp,
      register,
      who_pays,
      fetchPayers,
      payerOptions,
      companyPaymentMethod,

      // Validation
      resetForm,
      refFormAdd,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>
